var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"kr-group"},[(_vm.label !== '')?_c('label',{class:{'focus':_vm.inputHasFocus,
                      'top':_vm.inputHasFocus || _vm.query !== '',
                      'error': _vm.errors.length > 0},attrs:{"for":_vm.getId}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.query),expression:"query"}],class:{'kr-no-results':_vm.hasNoResults,
                      'error': _vm.errors.length > 0},attrs:{"id":_vm.getId,"name":_vm.name,"title":_vm.title,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.query)?_vm._i(_vm.query,null)>-1:(_vm.query)},on:{"blur":_vm.onBlur,"click":_vm.onOpen,"focus":_vm.onFocus,"input":_vm.onChange,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }return _vm.onArrowDown.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onEnter.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }return _vm.onArrowUp.apply(null, arguments)}],"change":function($event){var $$a=_vm.query,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.query=$$a.concat([$$v]))}else{$$i>-1&&(_vm.query=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.query=$$c}}}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.query),expression:"query"}],class:{'kr-no-results':_vm.hasNoResults,
                      'error': _vm.errors.length > 0},attrs:{"id":_vm.getId,"name":_vm.name,"title":_vm.title,"type":"radio"},domProps:{"checked":_vm._q(_vm.query,null)},on:{"blur":_vm.onBlur,"click":_vm.onOpen,"focus":_vm.onFocus,"input":_vm.onChange,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }return _vm.onArrowDown.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onEnter.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }return _vm.onArrowUp.apply(null, arguments)}],"change":function($event){_vm.query=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.query),expression:"query"}],class:{'kr-no-results':_vm.hasNoResults,
                      'error': _vm.errors.length > 0},attrs:{"id":_vm.getId,"name":_vm.name,"title":_vm.title,"type":_vm.type},domProps:{"value":(_vm.query)},on:{"blur":_vm.onBlur,"click":_vm.onOpen,"focus":_vm.onFocus,"input":[function($event){if($event.target.composing){ return; }_vm.query=$event.target.value},_vm.onChange],"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }return _vm.onArrowDown.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onEnter.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }return _vm.onArrowUp.apply(null, arguments)}]}}),(_vm.query)?_c('button',{staticClass:"kr-clear",on:{"click":_vm.clearField}},[_c('font-awesome-icon',{attrs:{"icon":"times"}})],1):_vm._e(),(_vm.source)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.code),expression:"code"}],attrs:{"id":_vm.getId + '_code',"name":_vm.name + '.code',"type":"hidden"},domProps:{"value":(_vm.code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.code=$event.target.value}}}):_vm._e(),(_vm.isLoading && _vm.inputHasFocus)?_c('div',{staticClass:"kr-loading-wrapper"},[_c('div',{staticClass:"kr-loading"},[_c('font-awesome-icon',{attrs:{"icon":"spinner","spin":""}})],1)]):_vm._e(),(_vm.hasNoResults && _vm.inputHasFocus)?_c('div',{staticClass:"kr-no-results-wrapper"},[_c('div',{staticClass:"kr-no-results"},[_vm._t("kr-no-results",function(){return [_vm._v("Aucun résultat trouvé pour \""+_vm._s(_vm.query)+"\". ")]},{"query":_vm.query})],2)]):_vm._e(),(_vm.errors.length > 0 || _vm.warnings.length > 0 || _vm.infos.length > 0)?_c('div',{staticClass:"kr-helper",class:{'error' : _vm.errors.length > 0,
                    'warning' : _vm.warnings.length > 0,
                    'info' : _vm.infos.length > 0}},[_c('strong',[(_vm.errors.length > 0)?[(Array.isArray(_vm.errors))?_vm._l((_vm.errors),function(err){return _c('li',{key:err},[_vm._v(_vm._s(err)+" ")])}):[_vm._v(_vm._s(_vm.errors))]]:(_vm.warnings.length > 0)?[(Array.isArray(_vm.warnings))?_vm._l((_vm.warnings),function(warn){return _c('li',{key:warn},[_vm._v(_vm._s(warn)+" ")])}):[_vm._v(_vm._s(_vm.warnings))]]:(_vm.infos.length > 0)?[(Array.isArray(_vm.infos))?_vm._l((_vm.infos),function(inf){return _c('li',{key:inf},[_vm._v(_vm._s(inf)+" ")])}):[_vm._v(_vm._s(_vm.infos))]]:_vm._e()],2)]):(_vm.selectedOption)?_c('div',{staticClass:"kr-helper"},[_c('strong',[_vm._t("kr-helper",null,{"option":_vm.selectedOption})],2)]):(_vm.placeholder.length > 0)?_c('div',{staticClass:"kr-helper"},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]):_vm._e(),(_vm.source)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],staticClass:"kr-autocomplete-options-wrapper"},[_c('ul',{staticClass:"kr-autocomplete-options",attrs:{"id":_vm.getId + '_autocomplete_options'}},_vm._l((_vm.results),function(option,i){return _c('li',{key:i,staticClass:"kr-autocomplete-option",class:{ 'is-active': i === _vm.arrowCounter },on:{"click":function($event){return _vm.setResult(option)}}},[_vm._t("kr-option-label",function(){return [_vm._v(_vm._s(_vm.getOptionLabelFunction(option))+" ")]},{"option":option})],2)}),0)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }