export default function (code_departement) {
        var link = "";
        switch (code_departement) {
            case '01':
        {
            link = 'https://www.ain.gouv.fr/Actions-de-l-Etat/Environnement-risques-naturels-et-technologiques/Risques-majeurs-naturels-et-technologiques/4.-Plans-de-Prevention-des-Risques-dans-l-Ain-PPR';
            break;
        }
        case '02':
        {
            link = 'https://www.aisne.gouv.fr/Actions-de-l-Etat/Amenagement-du-territoire/IAL-Information-acquereurs-et-locataires';
            break;
        }
        case '03':
        {
            link = 'https://www.allier.gouv.fr/Actions-de-l-Etat/Risques-naturels-et-technologiques/Plans-de-Prevention-des-Risques-Naturels-et-Technologiques';
            break;
        }
        case '04':
        {
            link = 'https://www.alpes-de-haute-provence.gouv.fr/Actions-de-l-Etat/Environnement-risques-naturels-et-technologiques/Prevention-des-Risques';
            break;
        }
        case '05':
        {
            link = 'https://www.hautes-alpes.gouv.fr/Actions-de-l-Etat/Environnement.-risques-naturels-et-technologiques/Risques-naturels-et-technologiques';
            break;
        }
        case '06':
        {
            link = 'https://www.alpes-maritimes.gouv.fr/Actions-de-l-Etat/Environnement-risques-naturels-et-technologiques/Les-risques-naturels-et-technologiques';
            break;
        }
        case '07':
        {
            link = 'https://www.ardeche.gouv.fr/Actions-de-l-Etat/Environnement.-risques-naturels-et-technologiques';
            break;
        }
        case '08':
        {
            link = 'https://www.ardennes.gouv.fr/Actions-de-l-Etat/Securite-et-protection-de-la-population/Prevention-des-risques-naturels-et-technologiques';
            break;
        }
        case '09':
        {
            link = 'https://www.ariege.gouv.fr/index.php/Actions-de-l-Etat/Securite-et-protection-de-la-population/Risques-naturels-et-technologiques';
            break;
        }
        case '10':
        {
            link = 'https://www.aube.gouv.fr/Actions-de-l-Etat/Securite-protection-de-la-population/Securite-civile/Risques/La-prevention-des-risques';
            break;
        }
        case '11':
        {
            link = 'https://www.aude.gouv.fr/Actions-de-l-Etat/Environnement/Prevention-des-risques';
            break;
        }
        case '12':
        {
            link = 'https://www.aveyron.gouv.fr/Actions-de-l-Etat/Environnement.-Energies.-Prevention-des-risques/Prevention-des-risques-naturels.-technologiques-et-miniers';
            break;
        }
        case '13':
        {
            link = 'https://www.bouches-du-rhone.gouv.fr/Actions-de-l-Etat/Environnement-risques-naturels-et-technologiques/La-prevention';
            break;
        }
        case '14':
        {
            link = 'https://www.calvados.gouv.fr/Actions-de-l-Etat/Environnement.-risques-naturels-et-technologiques/Prevention-des-risques/Plans-de-Prevention-des-risques/Accedez-aux-plans-de-prevention-des-risques-du-Calvados';
            break;
        }
        case '15':
        {
            link = 'https://www.cantal.gouv.fr/Action-de-l-Etat/Securite-publique/Risques-naturels-et-technologiques';
            break;
        }
        case '16':
        {
            link = 'https://www.charente.gouv.fr/Actions-de-l-Etat/Environnement-Chasse-Eau-Risques/Prevention-des-risques-naturels-et-technologiques';
            break;
        }
        case '17':
        {
            link = 'https://www.charente-maritime.gouv.fr/Actions-de-l-Etat/Environnement-risques-naturels-et-technologiques/Risques-naturels-et-technologiques';
            break;
        }
        case '18':
        {
            link = 'https://www.correze.gouv.fr/Action-de-l-Etat/Securite-et-protection-des-populations/Securite-Civile/Risques-naturels-et-technologiques';
            break;
        }
        case '19':
        {
            link = 'https://www.creuse.gouv.fr/Actions-de-l-Etat/Securite/Securite-civile/Preventions-des-aleas';
            break;
        }
        case '21':
        {
            link = 'https://www.cote-dor.gouv.fr/Actions-de-l-Etat/Risques-majeurs.-naturels-et-technologiques';
            break;
        }
        case '22':
        {
            link = 'https://www.cotes-darmor.gouv.fr/Actions-de-l-Etat/Risques-naturels-technologiques-et-miniers';
            break;
        }
        case '23':
        {
            link = 'https://www.creuse.gouv.fr/Actions-de-l-Etat/Securite/Securite-civile/Preventions-des-aleas';
            break;
        }
        case '24':
        {
            link = 'https://www.dordogne.gouv.fr/Actions-de-l-Etat/Environnement-Eau-Biodiversite-Risques/Risques-naturels-et-technologiques/Plans-de-prevention-des-risques';
            break;
        }
        case '25':
        {
            link = 'https://www.doubs.gouv.fr/Actions-de-l-Etat/Securite-et-protection-de-la-population/Risques-majeurs';
            break;
        }
        case '26':
        {
            link = 'https://www.drome.gouv.fr/Actions-de-l-Etat/Environnement-eau-risques-naturels-et-technologiques/Prevention-des-risques';
            break;
        }
        case '27':
        {
            link = 'https://www.eure.gouv.fr/Actions-de-l-Etat/Risques-majeurs';
            break;
        }
        case '28':
        {
            link = 'https://www.eure-et-loir.gouv.fr/Actions-de-l-Etat/Risques-naturels-et-technologiques';
            break;
        }
        case '29':
        {
            link = 'https://www.finistere.gouv.fr/Actions-de-l-Etat/Environnement-risques-naturels-et-technologiques/Risques-naturels-et-technologiques';
            break;
        }
        case '2A':
        {
            link = 'https://www.corse-du-sud.gouv.fr/Actions-de-l-Etat/Securite-protection-des-populations/Prevention-des-risques';
            break;
        }
        case '2B':
        {
            link = 'https://www.haute-corse.gouv.fr/Actions-de-l-Etat/Transition-ecologique-et-prevention-des-risques/Prevention-des-risques';
            break;
        }
        case '30':
        {
            link = 'https://www.gard.gouv.fr/Actions-de-l-Etat/Securite-et-protection-de-la-population/Risques';
            break;
        }
        case '31':
        {
            link = 'https://www.haute-garonne.gouv.fr/index.php/Actions-de-l-Etat/Environnement-eau-risques-naturels-et-technologiques';
            break;
        }
        case '32':
        {
            link = 'https://www.gers.gouv.fr/Actions-de-l-Etat/Prevention-des-risques-naturels-et-technologiques/Les-risques-majeurs-dans-le-departement-du-Gers/Les-plans-de-prevention-des-risques-PPR-dans-le-Gers';
            break;
        }
        case '33':
        {
            link = 'https://www.gironde.gouv.fr/Actions-de-l-Etat/Environnement-risques-naturels-et-technologiques/Prevention-des-risques-naturels-et-technologiques';
            break;
        }
        case '34':
        {
            link = 'https://www.herault.gouv.fr/Actions-de-l-Etat/Environnement-eau-chasse-risques-naturels-et-technologiques/Risques-naturels-et-technologiques/Les-Plans-de-Prevention-des-Risques-approuves';
            break;
        }
        case '35':
        {
            link = 'https://www.ille-et-vilaine.gouv.fr/Actions-de-l-Etat/Environnement-Risques-naturels-et-technologiques/Risques-Tous-resilients/Les-risques-naturels/Les-Plans-de-Prevention-du-Risque-Inondation';
            break;
        }
        case '36':
        {
            link = 'https://www.indre.gouv.fr/Actions-de-l-Etat/Securite-et-protection-de-la-population2/Prevention-des-risques-majeurs';
            break;
        }
        case '37':
        {
            link = 'https://www.indre-et-loire.gouv.fr/Actions-de-l-Etat/Risques-naturels-et-technologiques';
            break;
        }
        case '38':
        {
            link = 'https://www.isere.gouv.fr/Actions-de-l-Etat/Risques-majeurs';
            break;
        }
        case '39':
        {
            link = 'https://www.jura.gouv.fr/Actions-de-l-Etat/Environnement/Risques';
            break;
        }
        case '40':
        {
            link = 'https://www.landes.gouv.fr/Actions-de-l-Etat/Eau.-Environnement.-Risques-Naturels-et-Technologiques/Prevention-des-Risques';
            break;
        }
        case '41':
        {
            link = 'https://www.loir-et-cher.gouv.fr/Actions-de-l-Etat/Prevention-des-risques';
            break;
        }
        case '42':
        {
            link = 'https://www.loire.gouv.fr/Actions-de-l-Etat/Risques-naturels.-miniers-et-technologiques';
            break;
        }
        case '43':
        {
            link = 'https://www.haute-loire.gouv.fr/Actions-de-l-Etat/Environnement.-risques-naturels-et-technologiques/Risques-Miniers.-Naturels-et-Technologiques';
            break;
        }
        case '44':
        {
            link = 'https://www.loire-atlantique.gouv.fr/Actions-de-l-Etat/Risques-naturels-et-technologiques';
            break;
        }
        case '45':
        {
            link = 'https://www.loiret.gouv.fr/Actions-de-l-Etat/Securite-et-risques/Risques';
            break;
        }
        case '46':
        {
            link = 'https://www.lot.gouv.fr/Actions-de-l-Etat/Environnement.-risques-naturels-et-technologiques';
            break;
        }
        case '47':
        {
            link = 'https://www.lot-et-garonne.gouv.fr/Actions-de-l-Etat/Securite-et-protection-de-la-population/Risques-majeurs';
            break;
        }
        case '48':
        {
            link = 'https://www.lozere.gouv.fr/Actions-de-l-Etat/Environnement-Risques-naturels-et-technologiques';
            break;
        }
        case '49':
        {
            link = 'https://www.maine-et-loire.gouv.fr/Actions-de-l-Etat/Prevention-des-risques-naturels-et-technologiques';
            break;
        }
        case '50':
        {
            link = 'https://www.manche.gouv.fr/Actions-de-l-Etat/Environnement-risques-naturels-et-technologiques/Risques-Naturels-et-Technologiques';
            break;
        }
        case '51':
        {
            link = 'https://www.marne.gouv.fr/Actions-de-l-Etat/Risques-securite-et-protection-de-la-population/Protection-civile-et-gestion-des-risques';
            break;
        }
        case '52':
        {
            link = 'https://www.haute-marne.gouv.fr/Actions-de-l-Etat/Risques-naturels-et-technologiques';
            break;
        }
        case '53':
        {
            link = 'https://www.mayenne.gouv.fr/Actions-de-l-Etat/Risques-naturels-et-industriels/Prevention-des-risques/Les-plans-de-prevention-des-risques';
            break;
        }
        case '54':
        {
            link = 'https://www.meurthe-et-moselle.gouv.fr/Actions-de-l-Etat/Prevention-des-risques';
            break;
        }
        case '55':
        {
            link = 'https://www.meuse.gouv.fr/Actions-de-l-Etat/Prevention-des-risques';
            break;
        }
        case '56':
        {
            link = 'https://www.morbihan.gouv.fr/Actions-de-l-Etat/Risques-naturels-et-technologiques-majeurs-et-leurs-plans';
            break;
        }
        case '57':
        {
            link = 'https://www.moselle.gouv.fr/Actions-de-l-Etat/Securite/Risques-majeurs/Risques-Naturels-Miniers-et-Technologiques';
            break;
        }
        case '58':
        {
            link = 'https://www.nievre.gouv.fr/Actions-de-l-Etat/Prevention-des-risques-naturels-et-technologiques';
            break;
        }
        case '59':
        {
            link = 'https://www.nord.gouv.fr/Actions-de-l-Etat/Prevention-des-risques-naturels-technologiques-et-miniers/Informations-generales-sur-les-risques/La-prevention-des-risques';
            break;
        }
        case '60':
        {
            link = 'https://www.oise.gouv.fr/Actions-de-l-Etat/Environnement/Les-risques-naturels-et-technologiques/Les-documents-relatifs-aux-risques';
            break;
        }
        case '61':
        {
            link = 'https://www.orne.gouv.fr/Actions-de-l-Etat/Environnement.-transition-energetique-et-prevention-des-risques/Prevention-et-gestion-des-risques-naturels-et-technologiques';
            break;
        }
        case '62':
        {
            link = 'https://www.pas-de-calais.gouv.fr/Actions-de-l-Etat/Prevention-des-risques-majeurs/Plan-de-prevention-des-risques';
            break;
        }
        case '63':
        {
            link = 'https://www.puy-de-dome.gouv.fr/Actions-de-l-Etat/Environnement-eau-prevention-des-risques/Risques-naturels-et-technologiques';
            break;
        }
        case '64':
        {
            link = 'https://www.pyrenees-atlantiques.gouv.fr/Actions-de-l-Etat/Cadre-de-vie-eau-environnement-et-risques-majeurs/Plans-de-prevention-des-risques';
            break;
        }
        case '65':
        {
            link = 'https://www.hautes-pyrenees.gouv.fr/Actions-de-l-Etat/Securite-et-protection-de-la-population/Securite-civile';
            break;
        }
        case '66':
        {
            link = 'https://www.pyrenees-orientales.gouv.fr/Actions-de-l-Etat/Environnement-eau-risques-naturels-et-technologiques/Risques-naturels-et-technologiques';
            break;
        }
        case '67':
        {
            link = 'https://www.bas-rhin.gouv.fr/Actions-de-l-Etat/Prevention-des-risques-naturels-et-technologiques';
            break;
        }
        case '68':
        {
            link = 'https://www.haut-rhin.gouv.fr/Actions-de-l-Etat/Information-preventive-sur-les-risques-majeurs';
            break;
        }
        case '69':
        {
            link = 'https://www.rhone.gouv.fr/Actions-de-l-Etat/Securite-et-protection-de-la-population/La-securite-civile/Les-risques-majeurs/Les-risques-majeurs-dans-le-Rhone';
            break;
        }
        case '70':
        {
            link = 'https://www.haute-saone.gouv.fr/Actions-de-l-Etat/Environnement/Prevention-des-risques-et-nuisances/Risques-naturels-et-technologiques';
            break;
        }
        case '71':
        {
            link = 'https://www.saone-et-loire.gouv.fr/Actions-de-l-Etat/Environnement-risques-naturels-et-technologiques/Risques-naturels-et-technologiques';
            break;
        }
        case '72':
        {
            link = 'https://www.sarthe.gouv.fr/Actions-de-l-Etat/Environnement-transition-energetique-et-prevention-des-risques/Risques';
            break;
        }
        case '73':
        {
            link = 'https://www.savoie.gouv.fr/Actions-de-l-Etat/Paysages-environnement-risques-naturels-et-technologiques/Risques-naturels-et-technologiques';
            break;
        }
        case '74':
        {
            link = 'https://www.haute-savoie.gouv.fr/Actions-de-l-Etat/Prevenir-le-risque-et-se-proteger';
            break;
        }
        case '75':
        {
            link = 'https://www.prefectures-regions.gouv.fr/ile-de-france/Region-et-institutions/L-action-de-l-Etat/Amenagement-du-territoire-transport-et-environnement/Prevention-des-risques/Prevention-et-gestion-des-risques';
            break;
        }
        case '76':
        {
            link = 'https://www.seine-maritime.gouv.fr/Publications/Information-des-acquereurs-et-locataires-sur-les-risques-majeurs/Recherche-par-Plan-de-Prevention-des-Risques-PPR';
            break;
        }
        case '77':
        {
            link = 'https://www.seine-et-marne.gouv.fr/Actions-de-l-Etat/Securite-et-protection-de-la-population/Prevention-des-risques-naturels-et-technologiques';
            break;
        }
        case '78':
        {
            link = 'https://www.yvelines.gouv.fr/Actions-de-l-Etat/Environnement-et-prevention-des-risques/Prevention-des-risques';
            break;
        }
        case '79':
        {
            link = 'https://www.deux-sevres.gouv.fr/Actions-de-l-Etat/Environnement-eau-risques-naturels-et-technologiques/Prevention-des-risques-naturels-majeurs-et-technologiques';
            break;
        }
        case '80':
        {
            link = 'https://www.somme.gouv.fr/Actions-de-l-Etat/Risques';
            break;
        }
        case '81':
        {
            link = 'https://www.tarn.gouv.fr/Actions-de-l-Etat/Eau.-Environnement.-Prevention-des-risques/Prevention-des-risques-naturels-et-technologiques';
            break;
        }
        case '82':
        {
            link = 'https://www.tarn-et-garonne.gouv.fr/Actions-de-l-Etat/Environnement/Information-et-prevention-sur-les-risques-naturels-et-technologiques-majeurs';
            break;
        }
        case '83':
        {
            link = 'https://www.var.gouv.fr/Actions-de-l-Etat/Risques-naturels-et-technologiques';
            break;
        }
        case '84':
        {
            link = 'https://www.vaucluse.gouv.fr/Actions-de-l-Etat/Transition-ecologique-et-prevention-des-risques/Prevention-des-risques';
            break;
        }
        case '85':
        {
            link = 'https://www.vendee.gouv.fr/Actions-de-l-Etat/Prevention-des-risques-naturels-et-technologiques';
            break;
        }
        case '86':
        {
            link = 'https://www.vienne.gouv.fr/Actions-de-l-Etat/Environnement-risques-naturels-et-technologiques/Risques-naturels-et-technologiques-majeurs';
            break;
        }
        case '87':
        {
            link = 'https://www.haute-vienne.gouv.fr/Actions-de-l-Etat/Securite-et-protection-de-la-population/Protection-civile-et-gestion-des-risques/Prevention-des-risques-majeurs/Les-plans-de-prevention-des-risques-approuves-en-Haute-Vienne';
            break;
        }
        case '88':
        {
            link = 'https://www.vosges.gouv.fr/Actions-de-l-Etat/Informations-des-acquereurs-et-locataires-IAL';
            break;
        }
        case '89':
        {
            link = 'https://www.yonne.gouv.fr/Actions-de-l-Etat/Securite-et-prevention-des-risques/Risques-majeurs';
            break;
        }
        case '90':
        {
            link = 'https://www.territoire-de-belfort.gouv.fr/Actions-de-l-Etat/Securite-Prevention-Protection-de-la-population/Prevention-des-risques';
            break;
        }
        case '91':
        {
            link = 'https://www.essonne.gouv.fr/Actions-de-l-Etat/Environnement-risques-naturels-et-technologiques';
            break;
        }
        case '92':
        {
            link = 'https://www.hauts-de-seine.gouv.fr/Actions-de-l-Etat/Environnement-et-prevention-des-risques/Prevention-des-risques/Risques-majeurs';
            break;
        }
        case '93':
        {
            link = 'https://www.seine-saint-denis.gouv.fr/Actions-de-l-Etat/Environnement-paysage-risques-naturels-et-technologiques-bruit-nuisances-publicite/4-Risques-naturels-et-technologiques/Les-plans-de-prevention-des-risques/Plans-de-prevention-des-risques-naturels/Les-plans-de-prevention-des-risques-PPR-approuves';
            break;
        }
        case '94':
        {
            link = 'https://www.val-de-marne.gouv.fr/Actions-de-l-Etat/Environnement-et-prevention-des-risques';
            break;
        }
        case '95':
        {
            link = 'https://www.val-doise.gouv.fr/Actions-de-l-Etat/Environnement-risques-et-nuisances/Prevention-Risques';
            break;
        }
        case '971':
        {
            link = 'https://www.guadeloupe.gouv.fr/Actions-de-l-Etat/Securite/Prevention-securite-des-personnes-et-des-biens/Defense-et-protection-civiles/Information-preventive';
            break;
        }
        case '972':
        {
            link = 'https://www.martinique.gouv.fr/Actions-de-l-Etat/Securite-civile-Risques-majeurs/Risques-majeurs';
            break;
        }
        case '973':
        {
            link = 'https://www.guyane.gouv.fr/Actions-de-l-Etat/Securite-et-protection-de-la-population/Risques-majeurs';
            break;
        }
        case '974':
        {
            link = 'https://www.reunion.gouv.fr/Actions-de-l-Etat/Securite-protection-des-personnes-et-des-biens/Protection-civile/Prevention-des-risques-naturels-et-technologiques';
            break;
        }
        case '976':
        {
            link = 'https://www.mayotte.gouv.fr/Actions-de-l-Etat/Prevention-des-risques-et-securite/Prevention-des-risques2';
            break;
        }

    }
    return link;

}