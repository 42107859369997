<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
export default {
    name: 'App'
}
</script>

<style>
#app {
	color                   : #2C3E50;
	font-family             : 'Avenir', Helvetica, Arial, sans-serif;
	-webkit-font-smoothing  : antialiased;
	height                  : 100%;
	-moz-osx-font-smoothing : grayscale;
	/*margin-top              : 60px;*/
	text-align              : center;
}
</style>
