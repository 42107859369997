<template>
    <div>
        <section class="section section-white">

            <errors ref="resultsERRIAL"/>

            <br/>

            <div id="searchButtonsWrapper">
                <a @click="$emit('flow', -1)"
                   class="bouton">
                    <font-awesome-icon icon="chevron-left"/>
                    Revenir au résultat
                </a>
            </div>

            <div id="actionButtonsWrapper"></div>

            <div class="container bordered">
                <div>
                    <div style="margin-bottom: 20px"><span class="title">Parcelle(s) </span></div>
                    <span class="rightAlign">Adresse&nbsp;: </span><b><span v-if="avis.summary.adresse">{{ avis.summary.adresse }}, <br/><span class="rightAlign"/>{{ avis.summary.commune.codePostal }} {{
                        avis.summary.commune.nomCommune
                                                                                                        }}</span><span v-else-if="avis.summary.commune">{{
                        avis.summary.commune.codePostal
                                                                                                                                                        }}, {{ avis.summary.commune.nomCommune }}</span><span v-else><i>n/a</i></span></b><br/>
                    <span class="rightAlign">Code parcelle&nbsp;: </span><b><span v-if="avis.summary.codeParcelle && avis.summary.codeParcelle !== ''">{{
                        avis.summary.codeParcelle
                                                                                                                                                       }}</span><span v-else><i>n/a</i></span></b><br/>
                    <br>
                </div>
            </div>

            <div class="container bordered"
                 id="aide"
                 v-if="avis.ppr && avis.ppr.length > 0">
                <div>
                    <div style="margin-bottom: 20px"><span class="title">Informations complémentaires à renseigner</span></div>
                    <p>Si votre bien est sur une parcelle concernée par un ou plusieurs plans de prévention des risques (PPR). Il peut être concerné par l’obligation de réaliser certains travaux. Vous devez
                       consulter le PPR auprès de la commune ou sur <a :href="checkAndGetPrefectureLink(avis.summary.commune.codeDepartement)">le site de la préfecture</a> afin de vous en assurer.
                       S’il existe des prescriptions de travaux, il est alors nécessaire de préciser si les travaux ont été réalisés ou non. Si votre bien est concerné par une obligation de travaux, vous pouvez bénéficier d'une
                        aide de l'État, dans le cadre du Fonds de prévention des risques naturels majeurs (FPRNM).<br>
                        Pour plus de renseignements, contacter la direction départementale des territoires (DDT) de votre département ou votre Direction de l'environnement,
                        de l'aménagement et du logement (DEAL), si vous êtes en Outre-mer.<br><br>
                       Si votre bien a fait l'objet d'un ou plusieurs sinistres indemnisés au titre des catastrophes naturelles ou des catastrophes technologiques, les sinistres survenus doivent être indiqués.</p>
                    <p>Si vous ne connaissez pas ces informations, vous n'êtes pas obligé de les renseigner pour télécharger l'état des risques, mais vous devrez les compléter avant de remettre l'état des risques.</p>
                    <p>Si les informations saisies ne sont pas sincères, cela peut entraîner des risques juridiques par la suite.</p><br>
                </div>
            </div>

            <template v-if="avis.ppr && avis.ppr.length > 0">
                <div class="container bordered ppr"
                     v-bind:class="{test: !(((!plan.existsCarte && !plan.existsInGpu && !plan.existsInGeorisque) || plan.existsInGpu || plan.existsInGeorisque) && ((plan.datePrescription && plan.dateApprobation) || null == plan.datePrescription ))}"
                     v-bind:key="'plan_' + index"
                     v-for="(plan, index) in avis.ppr">

                    <div v-if="((!plan.existsCarte && !plan.existsInGpu && !plan.existsInGeorisque) || plan.existsInGpu || plan.existsInGeorisque) && ((plan.datePrescription && plan.dateApprobation) || null == plan.datePrescription )">
                        <div class="errial_title"><span class="title">{{ plan.alea.familleAlea.aleaNiv1.length === 1? plan.alea.familleAlea.famillePPR.libelle :'Multirisques' }}</span></div>
                        <p>Rappel du risque : {{ plan.alea.familleAlea.aleaNiv1.length === 1? plan.alea.familleAlea.aleaNiv1[0].libelle :'Multirisques'  }}{{ (plan.dateApprobation ? ', PPR approuvé le ' + formatDate(plan.dateApprobation) : ', PPR prescrit le ' + formatDate(plan.datePrescription)) }}.</p>
                        <div class="text_wrapper"><b>L'immeuble est concerné par des prescriptions de travaux dans le règlement du {{ plan.alea.familleAlea.famillePPR.libelle }}</b></div>
                        <div class="input_wrapper">
                            <label><input :name="'pre_' + plan.idGaspar"
                                          value="1"
                                          type="checkbox"
                                          @click="onlyOne('1', 'pre_'+ plan.idGaspar)">Oui</label>
                            <label><input :name="'pre_' + plan.idGaspar"
                                          value="0"
                                          type="checkbox"
                                          @click="onlyOne('0', 'pre_'+ plan.idGaspar)">Non</label>
                        </div>

                        <div class="text_wrapper"><b>Si oui, les travaux prescrits ont été réalisés</b></div>
                        <div class="input_wrapper">
                            <label><input :name="'tra_' + plan.idGaspar"
                                          value="1"
                                          type="checkbox"
                                          @click="onlyOne('1', 'tra_'+ plan.idGaspar)">Oui</label>
                            <label><input :name="'tra_' + plan.idGaspar"
                                          value="0"
                                          type="checkbox"
                                          @click="onlyOne('0', 'tra_'+ plan.idGaspar)">Non</label>
                        </div>
                        <p style="font-weight: bold;"> Vous n'êtes pas obligé de renseigner ces/cette information(s) pour télécharger l'état des risques, mais vous devrez les/la compléter avant de remettre l'état des risques.</p>
                    </div>
                </div>
            </template>

            <div class="container bordered ppr">
                <div>
                    <div class="errial_title"><span class="title">Information relative aux sinistres indemnisés par l'assurance à la suite d'une catastrophe naturelle, minière ou technologique</span></div>
                    <p>Le bien a-t-il fait l'objet d'indemnisation par une assurance suite à des dégâts liés à une catastrophe ?</p>
                    <div class="input_wrapper">
                        <label><input name="cat"
                                      value="1"
                                      type="checkbox"
                                      @click="onlyOne('1', 'cat')">Oui</label>
                        <label><input name="cat"
                                      value="0"
                                      type="checkbox"
                                      @click="onlyOne('0', 'cat')">Non</label>
                        </div>

                    <p>Pour répondre à cette question, vous pourrez consulter la liste des arrêtés de catastrophes naturelles pris sur la commune après avoir généré le pdf à l'étape suivante (cf. annexe 2).</p>
                    <p style="font-weight: bold;"> Vous n'êtes pas obligé de renseigner ces/cette information(s) pour télécharger l'état des risques, mais vous devrez les/la compléter avant de remettre l'état des risques.</p>
                    
                </div>
            </div>

            <div id="bottomButtonsWrapper">
                <a @click="download"
                   class="bouton success">
                    Télécharger l'état des risques
                    <font-awesome-icon class="end"
                                       icon="chevron-right"/>
                </a>
            </div>

        </section>
    </div>
</template>

<script>

import Errors from "../base/Errors";
import JQuery from 'jquery'
import moment from "moment";
import prefectureLink from "../../../script/prefectureLink";

let $ = JQuery

export default {
    name: 'CompleterERRIAL',
    components: {
        Errors
    },
    props: {
        avis: {
            type: Object,
            default: () => {
            }
        }
    },
    computed: {
        _paq: function () {
            return window._paq
        }
    },
    methods: {
        checkAndGetPrefectureLink (value) {
            return prefectureLink(value)
        },
        formatDate (date) {
            return moment(date).format('DD/MM/YYYY')
        },
        download () {

            let errial = ''

            $("[name^='pre_']:checked").each(function () {
                if ($(this).val() !== "-1") {
                    errial += $(this).attr('name') + ":" + $(this).val() + ";"
                }
            })

            $("[name^='tra_']:checked").each(function () {
                if ($(this).val() !== "-1") {
                    errial += $(this).attr('name') + ":" + $(this).val() + ";"
                }
            })

            let catnat = $("[name='cat']:checked")

            if (catnat.val() !== "-1") {
                errial += "cat:" + catnat.val() + ";"
            }

            this.$emit('errial', errial)
        },
        onlyOne(checkbox, name) {
            var checkboxes = document.getElementsByName(name)
            checkboxes.forEach((item) => {
                if (item.value !== checkbox) item.checked = false
            })
        }        
    }
}
</script>

<style scoped>

#searchButtonsWrapper {
	float : left;
}

#searchButtonsWrapper a,
#actionButtonsWrapper a,
#bottomButtonsWrapper a {
	display : inline-block;
	float   : none;
}

#bottomButtonsWrapper {
	flex       : 0 0 100%;
	margin-top : 25px;
	text-align : center;
}

#actionButtonsWrapper {
	float : right;
}

@media (min-width : 630px) {
	#searchButtonsWrapper a:last-of-type,
	#actionButtonsWrapper a:last-of-type {
		margin-right : 0;
	}
}

@media (max-width : 1350px) {

	#searchButtonsWrapper {
		text-align : center;
		width      : 100%;
	}

	#actionButtonsWrapper {
		text-align : center;
		width      : 100%;
	}
}

@media (max-width : 630px) {
	#searchButtonsWrapper a,
	#actionButtonsWrapper a {
		margin-left  : 10px;
		margin-right : 10px;
	}
}

.container {
	max-width : unset;
}

.container.bordered {
	background-color : #FFFFFF;
	border           : 1px solid #CCCCCC;
	border-radius    : 2px;
	display          : flex;
	margin-bottom    : 20px;
	padding          : 20px;
	text-align       : left;
	width            : 100%;
}

.container.bordered span {
	line-height : 25px;
}

.container.bordered span.rightAlign {
	display       : inline-block;
	padding-right : 5px;
	text-align    : right;
	width         : 150px;
}

.container.bordered.ppr > div {
	display   : flex;
	flex-wrap : wrap;
}

.container.bordered.ppr div.errial_title {
	flex          : 1 0 100%;
	margin-bottom : 20px;
}

.container.bordered.ppr p {
	flex : 1 0 100%;
}

.text_wrapper {
	clear          : both;
	flex           : 1 0 50%;
	padding-bottom : 25px;
	text-align     : end;
}

.input_wrapper {
	flex : 1 0 350px;
}

.input_wrapper label {
	display     : inline-block;
	margin-left : 20px;
}

#aide {
	background-color : #DAF5E7;
	border-color     : #5CD495;
}

.test {
  border: 0 !important;
}
</style>
